<template>
  <div class="columns">
    <section class="column is-full hero is-medium has-background-white-bis">
      <div class="hero-body has-background-white-bis">
        <div class="columns is-vcentered">
          <div class="column is-half">
          
            <b-field>
                <b-tag>Last updated, July 2023</b-tag>
            </b-field>

            <p class="title hPTitle">
              Vanessa Greene
            </p>
            <p class="is-size-5">
              &#129337; Community Coordinator
            </p>
            <p class="is-size-5">
              &#127757; Exploring the world throught STEM and sustainablity.
            </p>

            <p> 
              <br>
              <b-taglist attached>
                    <b-tag type="is-primary">Currently reading: </b-tag>
                    <b-tag type="is-dark"> Not On The Label by Felicity Lawrence</b-tag>
              </b-taglist>
              <b-taglist attached>
                    <b-tag type="is-success">Last read: </b-tag>
                    <b-tag type="is-dark"> Loved Clothes Last by Orsola de Castro</b-tag>
            </b-taglist>

            </p>
          </div>

          <div class="column is-offset-1 is-one-third">
            <span class="dot is-flex-shrink-1"></span>
            <b-image
            src="https://firebasestorage.googleapis.com/v0/b/vg-ie-b1bb2.appspot.com/o/assets%2FVanessaG_headshot.png?alt=media&token=c5623eb0-13b7-4173-b681-525dcf232a00"
            alt="headshot of Vanessa Greene"
            ratio="2by2"
            class="vgImg"
            ></b-image>
            <span class="dot2 is-flex-shrink-1"></span>
            <span class="dot1 is-flex-shrink-1"></span>
            <!--<img src="@/assets/images/vanessaImageHome.png" class="image"/>-->
          </div>
        </div>
      </div>
       <!--END HERO-->
      <div>
        <About />
    </div>
    </section>
   
    
  </div>
</template>

<script>
import About from '@/components/About'

export default {
  name: 'Home',
  components: {
    About,
  }
}
</script>

<style scoped>
.hPTitle {
  font-size: 80px;
}

.hero-body.subtitle {
  font-family: 'Poppins', sans-serif;
}

.bColHero {
  background-color: #FFFFFF;
}

.vgImg {
  margin-top: -25%;
}

.dot {
  height: 150px;
  width: 150px;
  background-color: #FFEE93;
  border-radius: 50%;
  margin-top: -5%;
  display: block;
}

.dot1 {
  height: 250px;
  width: 250px;
  background-color: #6CD4FF;
  border-radius: 50%;
  margin-top: -40%;
  margin-right: -15%;
  float: right;
  display: block;
}

.dot2 {
  height: 200px;
  width: 200px;
  background-color: #FF7B9C;
  border-radius: 50%;
  margin-top: -35%;
  float: left;
  display: block;
}

</style>
